import Link from 'next/link';

import { FC } from 'react';

import { LABELS } from '../../../shared/consts';
import { fireIcon } from '../../../shared/icons';


import type { BankOffer } from '../types';

export const CardBankInfo: FC<{
  item: BankOffer;
  isLoading: boolean;
  isSuccess: boolean;
  offeredBankId: number | null;
  onSendOffer: (id: number) => void;
}> = ({ item, onSendOffer, isLoading, isSuccess, offeredBankId }) => {
  return (
    <div className='flex justify-between w-full'>
      <div>
        <div className="flex items-center gap-8">
          {/* <img
        className="w-[48px] h-[77px]"
        src={`data:image/${item?.productImageExtension};base64, ${item?.productImage}`}
        alt={item.productName}
      /> */}
          <div className="flex flex-col gap-2">
            <span className="text-[#25282B] font-[600]">{item.productName}</span>
            <div className="flex rounded-lg gap-2">
              <img
                className="w-5 h-5"
                src={`data:image/${item?.bankImageExtension};base64, ${item?.bankImage}`}
                alt={item.bankName}
              />
              <span className="text-[#6E757C] font-semibold text-[12px]">{item?.bankName}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 xxs:grid-cols-1 lg:place-items-start min-w-[750px] relative">
        <div className="flex flex-col gap-1">
          <span className="text-[#6E757C] font-medium">{LABELS.day.label}</span>
          <div className="text-[#25282B] font-semibold flex lg:items-center xxs:items-end gap-1">
            <span>{`${item?.gracePeriod ? item?.gracePeriod + LABELS.day.suffix : 'Məlum deyil'}`}</span>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-[#6E757C] font-medium">{LABELS.percent.label}</span>
          <div className="text-[#25282B] font-semibold flex lg:items-center xxs:items-end gap-1">
            <span>{`${item?.amount} ${LABELS.creditAmount.suffix}`}</span>
            <fireIcon.icon width={24} height={24} />
          </div>
        </div>
        <Link
          target='_blank'
          href={item.productLink || ''}
          // type="button"
          // onClick={() => onSendOffer(item?.id)}
          className={`inline absolute right-0  self-end text-center lg:min-h-[48px] xxs: min-h-[32px] lg:py-3 xxs:py-2 lg:text-base xxs:text-[12px] rounded-md w-full text-white font-semibold border-none outline-none ${isSuccess && offeredBankId === item.id ? 'bg-[#2AC769]' : 'bg-[#5956E9]'} max-w-[152px]`}
        >
          {
          // offeredBankId === item.id && isLoading ? (
          //   <Loading />
          // ) : offeredBankId === item.id && isSuccess ? (
          //   <Success />
          // ) : (
            'Sorğu göndər'
          // )
          }
        </Link>
      </div>
    </div>
  );
};
