import { FC, useMemo } from 'react';

import { BANNERS, TabToBankBanner } from './consts';

const Banner: FC<any> = ({ currentTab }) => {
  const currentBank = BANNERS[TabToBankBanner[currentTab]];

  const isExpressbank = currentBank.bankName === 'Expressbank';

  const isSmallDevices = () => {
    if (typeof window !== 'undefined') return window.innerWidth < 780;
  };

  return (
    <section className="xxs:pt-0">
      <h2 className="text-[#2F2F2F] block mx-auto font-bold lg:text-[28px] xxs:text-[20px] max-w-[1048px]lg:pb-8 xxs:pb-4">Ən yaxşı təkliflər hamısı bir yerdə</h2>
      <main
        className="max-w-[1048px] mx-auto bg-center bg-no-repeat rounded-[20px] p-0 sm:p-2"
        style={{ background: currentBank.backgroundColor }}
      >
        <div className="flex items-center justify-between h-[280px]">
          <div className="flex flex-col pl-8 pr-0 xxs:pr-8">
            <div className="flex items-center gap-1.5 mb-4 lg:justify-start">
              <span className="font-bold xxs:text-[18px] lg:text-2xl">{currentBank.title}</span>
              <currentBank.title_icon />
            </div>
            <p className="text-gray-700 leading-6 font-semibold mb-8 max-w-[680px] xxs:text-[14px]">
              {currentBank.description}
            </p>
            <div
              className={`flex gap-8 items-center xxs:justify-between lg:justify-normal lg:mr-0  ${isExpressbank && 'mt-2'}`}
            >
              <div className="flex gap-4 items-center">
                <currentBank.bankIcon />
                <span className="text-gray-900 font-semibold">{currentBank.bankName}</span>
              </div>
            </div>
          </div>
          {!isSmallDevices() && (
            <div className="self-end">
              <img src={currentBank.backgroundImage} alt={currentBank.bankName} />
            </div>
          )}
        </div>
      </main>
    </section>
  );
};

export default Banner;
