import type { ICardRequest, ICashRequest } from './types';

const DEFAULT = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const smartListCard = async ({ labelId, amount }: any) => {
  const response = await fetch(
    `${process.env.API_URL}/smart/list-card?labelId=${labelId}&amount=${amount}`,
  ).then(res => res.json() as Promise<any[]>);

  return response;
};

export const smartListCash = async ({ monthCount, amount }: any) => {
  const response = await fetch(
    `${process.env.API_URL}/smart/list-cash?monthCount=${String(monthCount)}&amount=${amount}`,
  ).then(res => res.json() as Promise<any[]>);

  return response;
};

export const topOffers = async () => {
  const response = await fetch(`${process.env.API_URL}/smart/get-top`).then(
    res => res.json() as Promise<any[]>,
  );

  return response;
};

export const saveCashRequest: any = async (customerData: ICashRequest) => {
  const response = await fetch(`${process.env.API_URL}/smart/save-cash-request`, {
    method: 'POST',
    headers: DEFAULT.headers,
    body: JSON.stringify(customerData),
  }).then(res => res.json() as Promise<any[]>);

  return response;
};

export const saveCardRequest: any = async (customerData: ICardRequest) => {
  const response = await fetch(`${process.env.API_URL}/smart/save-card-request`, {
    method: 'POST',
    headers: DEFAULT.headers,
    body: JSON.stringify(customerData),
  }).then(res => res.json() as Promise<any[]>);

  return response;
};
