import { FC, useEffect, useState } from 'react';

import { Loading } from './ui/Loading';
import { CashBankInfo } from './ui/CashBankInfo';
import { CardBankInfo } from './ui/CardBankInfo';

import { noop } from '@/lib/utils';

import { useFetchList } from './hooks/useFetchList';

import { useSaveRequest } from './hooks/useSaveRequest';

import type { BankOffer, BankOfferTag } from './types';
import { icons } from './consts';

const isCashFunc = (t: string) => t === 'cash';

const ResultContainer: FC<any> = ({ values, currentTab }) => {
  const {
    reset,
    saveRequest,
    isPending: isSaveRequestPending,
    isSuccess: isSaveRequestSucceed,
  } = useSaveRequest(currentTab);

  const { data, refetch, isLoading, isSuccess, isFetching, isError } = useFetchList(
    values,
    currentTab,
  );

  const [offeredBankId, setOfferedBankId] = useState<number | null>(null);

  const onSendOffer = async (bankId: number) => {
    setOfferedBankId(bankId);

    const payload: any = {
      companyId: bankId,
      amount: values?.amount,
      phone: values?.mobileNumber,
      ...(isCashFunc(currentTab)
        ? { monthCount: values?.monthCount }
        : { labelId: values?.labelId?.id }),
    };

    void saveRequest(payload);
  };

  useEffect(() => {
    if (values) refetch();
    return noop;
  }, [values]);

  useEffect(reset, [currentTab]);

  const handleDivClick = (e: any, bankId: number) => {
    setOfferedBankId(bankId);
  };

  return (
    <section>
      <div className="max-w-[1048px] mx-auto flex flex-col gap-4">
        <span className="font-bold text-lg text-gray-900 relative mt-4">{`${data?.object?.length ?? 0} nəticə`}</span>
        {(isSuccess && !data?.object?.length) && (
          <>
            <div className="bg-white border border-[#DCDCDC] p-8 rounded-[20px] flex flex-col items-center justify-center gap-4">
              <icons.sad />
              <p className="text-[20px] font-[500] text-[#2f2f2f] text-center">
                Axtardığınız parametrlər üzrə məlumat tapılmadı
              </p>
            </div>
          </>
        )}
        {(isFetching || isLoading) && (
          <div className="mx-auto mt-6">
            <Loading color={'bg-[#5956E9]'} />
          </div>
        )}
        {isSuccess &&
          data?.object?.map((item: BankOffer) => (
            <div
              key={item?.id}
              style={{
                padding: '1px',
                cursor: 'pointer',
                borderRadius: '20px',
                background:
                  offeredBankId === item?.id
                    ? 'linear-gradient(180deg, #5956E9 0%, #DB14F1 100%)'
                    : 'transparent',
              }}
              onClick={e => handleDivClick(e, item?.id)}
            >
              <div
                key={item?.bankName}
                className="bg-white border border-[#DCDCDC] p-6 rounded-[20px]"
              >
                <div className="flex lg:flex-row xxs:flex-col gap-6 justify-between border-b pb-4">
                  {isCashFunc(currentTab) ? (
                    <CashBankInfo
                      isSuccess={isSaveRequestSucceed}
                      isLoading={isSaveRequestPending}
                      {...{ item, onSendOffer, offeredBankId }}
                    />
                  ) : (
                    <CardBankInfo
                      isSuccess={isSaveRequestSucceed}
                      isLoading={isSaveRequestPending}
                      {...{ item, onSendOffer, offeredBankId }}
                    />
                  )}
                </div>

                <div className="flex lg:justify-between lg:items-center lg:flex-end lg:flex-row xxs:flex-col xxs:gap-2">
                  <div className="flex mt-4 gap-[.5rem]">
                    {item?.labels.map((o: BankOfferTag) => (
                      <div
                        className={`${
                          item?.labels[0].id === o?.id
                            ? 'text-white bg-[#e541ed]'
                            : 'text-[#25282B] bg-[#F6F6F6]'
                        } font-semibold rounded-full min-w-[84px] py-1 px-2 text-center text-[12px]`}
                        key={item?.id}
                      >
                        {o?.name}
                      </div>
                    ))}
                  </div>
                  {isSaveRequestSucceed && offeredBankId === item.id && (
                    <p className="text-[#6E757C] font-[500] lg:self-end lg:mt-4 lg:text-base xxs:text-[12px]">
                      Sizinlə yaxın zamanda əlaqə saxlanılacaq
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default ResultContainer;
