export interface Label {
  label: string;
  suffix: string;
}

export interface Labels {
  day: Label;
  date: Label;
  creditLimit: Label;
  percent: Label;
  creditAmount: Label;
}

export const LABELS: Labels = {
  day: {
    label: 'Güzəşt müddəti',
    suffix: 'gün',
  },
  creditLimit: {
    label: 'Kredit limiti',
    suffix: 'AZN-dək',
  },
  date: {
    label: 'Müddət',
    suffix: 'ayadək',
  },
  creditAmount: {
    label: 'Məbləğ',
    suffix: 'AZN-dək',
  },
  percent: {
    label: 'Faiz dərəcəsi',
    suffix: '%-dən başlayaraq',
  },
};
