import * as Yup from 'yup';

const validationSchema = (key: string) => {
  const message = key === 'monthAmount' ? 'Ayların sayı' : 'Bonus tipi';
  
  return Yup.object().shape({
    amount: Yup.string().required('Kedit məbləği qeyd edilməlidir!'),
    mobileNumber: Yup.string()
      .matches(/^\+994 \(\d{2}\) \d{3} \d{2} \d{2}$/, 'Format düzgün qeyd edilməyib!')
      .required('Mobil nömrə qeyd edilməlidir!'),
    [key]: Yup.mixed().required(`${message} qeyd edilməlidir!`),
  });
};

export default validationSchema;
