export const CREDIT_TYPES = [
  {
    id: 'cash',
    name: 'Nağd kredit',
    icon: () => (
      <svg
        width="26"
        height="30"
        viewBox="0 0 26 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.9236 21.4469C24.0485 16.1818 21.3242 12.2612 18.9559 9.95834C18.2668 9.28824 17.9222 8.95318 17.161 8.6431C16.3999 8.33301 15.7456 8.33301 14.437 8.33301H11.563C10.2544 8.33301 9.60013 8.33301 8.83896 8.6431C8.07779 8.95318 7.73321 9.28824 7.04405 9.95834C4.67576 12.2612 1.95147 16.1818 1.07636 21.4469C0.42524 25.3642 4.03903 28.333 8.07776 28.333H17.9222C21.961 28.333 25.5748 25.3642 24.9236 21.4469Z"
          stroke="#5956E9"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          opacity="0.4"
          d="M13.8867 13.666C13.8867 13.1137 13.4389 12.666 12.8867 12.666C12.3344 12.666 11.8867 13.1137 11.8867 13.666L13.8867 13.666ZM11.8867 22.9993C11.8867 23.5516 12.3344 23.9993 12.8867 23.9993C13.4389 23.9993 13.8867 23.5516 13.8867 22.9993H11.8867ZM14.3168 16.2711C14.651 16.7108 15.2784 16.7963 15.7181 16.4621C16.1578 16.1279 16.2433 15.5006 15.9091 15.0609L14.3168 16.2711ZM11.3322 20.469C10.9207 20.1007 10.2885 20.1357 9.92015 20.5473C9.55184 20.9588 9.58689 21.591 9.99843 21.9593L11.3322 20.469ZM12.8867 17.1985C12.0236 17.1985 11.6307 17.0576 11.4744 16.9523C11.4026 16.904 11.3359 16.8493 11.3359 16.5853H9.33594C9.33594 17.3607 9.61706 18.1126 10.3569 18.611C11.0122 19.0524 11.8947 19.1985 12.8867 19.1985V17.1985ZM11.3359 16.5853C11.3359 16.458 11.4037 16.2606 11.6767 16.0592C11.9495 15.8579 12.374 15.7033 12.8867 15.7033V13.7033C11.9906 13.7033 11.1398 13.9699 10.4893 14.4498C9.83917 14.9295 9.33594 15.6732 9.33594 16.5853H11.3359ZM14.6693 20.0805C14.6693 20.3764 14.5641 20.5334 14.3393 20.6684C14.0539 20.8398 13.5641 20.9625 12.8867 20.9625V22.9625C13.746 22.9625 14.6475 22.8164 15.3692 22.3829C16.1515 21.9129 16.6693 21.1288 16.6693 20.0805H14.6693ZM12.8867 19.1985C13.7578 19.1985 14.212 19.3325 14.4273 19.473C14.5527 19.5547 14.6693 19.6744 14.6693 20.0805H16.6693C16.6693 19.1423 16.3221 18.3209 15.5199 17.7978C14.8077 17.3332 13.8706 17.1985 12.8867 17.1985V19.1985ZM13.8867 14.7033L13.8867 13.666L11.8867 13.666L11.8867 14.7033L13.8867 14.7033ZM11.8867 21.9625V22.9993H13.8867V21.9625H11.8867ZM12.8867 15.7033C13.5998 15.7033 14.1076 15.9959 14.3168 16.2711L15.9091 15.0609C15.2449 14.1871 14.086 13.7033 12.8867 13.7033V15.7033ZM12.8867 20.9625C12.1755 20.9625 11.6228 20.729 11.3322 20.469L9.99843 21.9593C10.7238 22.6085 11.783 22.9625 12.8867 22.9625V20.9625Z"
          fill="#E541ED"
        />
        <path
          opacity="0.4"
          d="M6.6781 4.92317C6.40302 4.52279 6.0043 3.97934 6.82793 3.85541C7.6745 3.72803 8.55355 4.30754 9.414 4.29563C10.1924 4.28485 10.589 3.93961 11.0145 3.44666C11.4625 2.92758 12.1562 1.66602 13.0026 1.66602C13.849 1.66602 14.5427 2.92758 14.9907 3.44666C15.4162 3.93961 15.8128 4.28485 16.5912 4.29563C17.4517 4.30754 18.3307 3.72803 19.1773 3.85541C20.0009 3.97934 19.6022 4.52279 19.3271 4.92317L18.0833 6.73353C17.5513 7.50796 17.2852 7.89518 16.7285 8.11393C16.1718 8.33268 15.4524 8.33268 14.0135 8.33268H11.9917C10.5528 8.33268 9.8334 8.33268 9.27668 8.11393C8.71996 7.89518 8.45393 7.50796 7.92187 6.73354L6.6781 4.92317Z"
          stroke="#E541ED"
          strokeWidth="2"
        />
      </svg>
    ),
    description: 'İstədiyin şərtlərlə – İndi müraciət et!',
  },
  {
    id: 'card',
    name: 'Kredit kartı',
    icon: () => (
      <svg
        width="30"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66406 11.9997C1.66406 7.28303 1.66406 4.92471 3.0678 3.35021C3.29231 3.09838 3.53977 2.86548 3.80734 2.65417C5.48024 1.33301 7.98596 1.33301 12.9974 1.33301H16.9974C22.0088 1.33301 24.5146 1.33301 26.1875 2.65417C26.455 2.86548 26.7025 3.09838 26.927 3.35021C28.3307 4.92471 28.3307 7.28303 28.3307 11.9997C28.3307 16.7163 28.3307 19.0746 26.927 20.6491C26.7025 20.901 26.455 21.1339 26.1875 21.3452C24.5146 22.6663 22.0088 22.6663 16.9974 22.6663H12.9974C7.98596 22.6663 5.48024 22.6663 3.80734 21.3452C3.53977 21.1339 3.29231 20.901 3.0678 20.6491C1.66406 19.0746 1.66406 16.7163 1.66406 11.9997Z"
          stroke="#5956E9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M12.3281 17.333H14.3281"
          stroke="#E541ED"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M18.3281 17.333L22.9948 17.333"
          stroke="#5956E9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1.66406 8H28.3307" stroke="#5956E9" strokeWidth="1.5" strokeLinejoin="round" />
      </svg>
    ),
    description: 'Bir kliklə ən yaxşı kredit kartını seç!',
  },
];
