export const fireIcon: any = {
  icon: (props: any) => (
    <svg
      width={`${props.width || 36}`}
      height={`${props.height || 36}`}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint0_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint1_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint2_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint3_linear_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint4_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint5_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint6_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint7_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint8_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint9_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint10_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint11_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint12_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint13_radial_196_4584)"
        fillOpacity="0.2"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint14_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint15_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint16_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint17_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint18_radial_196_4584)"
        fillOpacity="0.3"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint19_radial_196_4584)"
        fillOpacity="0.4"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint20_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint21_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint22_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint23_radial_196_4584)"
        fillOpacity="0.8"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint24_radial_196_4584)"
        fillOpacity="0.2"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint25_radial_196_4584)"
        fillOpacity="0.2"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint26_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint27_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint28_radial_196_4584)"
      />
      <path
        d="M14.1313 10.0527C15.8601 7.35555 16.8833 4.86467 17.4173 3.10367C17.6219 2.42873 18.4861 2.15226 18.9992 2.63615C26.7307 9.92755 29.2577 15.4943 29.762 21.9277C30.1311 28.1504 27.0725 33.6875 18.8635 33.6875C11.1565 33.6875 5.46889 28.3086 6.24229 19.7832C6.7045 14.6881 8.6853 10.8066 10.2689 8.49596C10.7618 7.77686 11.7886 7.76643 12.3566 8.42782L13.7732 10.0774C13.8708 10.191 14.0505 10.1788 14.1313 10.0527Z"
        fill="url(#paint29_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint30_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint31_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint32_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint33_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint34_linear_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint35_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint36_radial_196_4584)"
      />
      <path
        d="M11.454 22.3613C12.9192 18.8746 15.9254 15.0162 17.9557 12.9232C18.5293 12.3318 19.4565 12.3213 20.0624 12.8795C23.9563 16.4672 25.1285 19.7106 26.1232 23.4863C27.158 27.4145 26.1232 33.7436 18.3364 33.7436C11.6305 33.7436 9.30755 27.4694 11.454 22.3613Z"
        fill="url(#paint37_radial_196_4584)"
        fillOpacity="0.7"
      />
      <defs>
        <radialGradient
          id="paint0_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.009 22.1855) rotate(-179.474) scale(19.2361 29.0093)"
        >
          <stop stopColor="#FF953D" />
          <stop offset="1" stopColor="#FF5141" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.6755 17.6123) rotate(-157.937) scale(11.6371 20.0807)"
        >
          <stop stopColor="#CE5327" />
          <stop offset="1" stopColor="#CE5327" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.88059 22.4524) rotate(15.4786) scale(2.36609 8.62131)"
        >
          <stop stopColor="#F38758" />
          <stop offset="0.363071" stopColor="#F38758" stopOpacity="0.703001" />
          <stop offset="0.71349" stopColor="#F38758" stopOpacity="0.210142" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_196_4584"
          x1="20.6349"
          y1="33.6875"
          x2="20.6349"
          y2="27.9517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7583" />
          <stop offset="1" stopColor="#FF7583" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.58331 16.1908) rotate(24.0149) scale(3.49274 28.9115)"
        >
          <stop stopColor="#FFAA7B" />
          <stop offset="0.845295" stopColor="#FFAA7B" stopOpacity="0.0923529" />
          <stop offset="1" stopColor="#FFAA7B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.4806 7.03285) rotate(77.3196) scale(4.3204 5.39086)"
        >
          <stop stopColor="#FF5E47" />
          <stop offset="1" stopColor="#FF5E47" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.4891 1.53711) rotate(87.8819) scale(11.4046 10.5561)"
        >
          <stop stopColor="#FF2F3C" />
          <stop offset="1" stopColor="#FF2F3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5964 9.86065) rotate(152.832) scale(1.40179 0.595881)"
        >
          <stop stopColor="#FFA682" />
          <stop offset="0.686162" stopColor="#FFA682" stopOpacity="0.266248" />
          <stop offset="0.821066" stopColor="#FFA682" stopOpacity="0.117186" />
          <stop offset="1" stopColor="#FFA682" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.4279 9.85497) rotate(114.808) scale(4.35682 0.431436)"
        >
          <stop stopColor="#FFA682" />
          <stop offset="0.701678" stopColor="#FFA682" stopOpacity="0.199672" />
          <stop offset="1" stopColor="#FFA682" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.7113 11.2636) rotate(20.9596) scale(1.00518 4.76753)"
        >
          <stop stopColor="#FF815B" />
          <stop offset="0.24804" stopColor="#FF815B" stopOpacity="0.869521" />
          <stop offset="0.815487" stopColor="#FF815B" stopOpacity="0.150116" />
          <stop offset="1" stopColor="#FF815B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.478 9.82139) rotate(16.4224) scale(1.23363 4.08008)"
        >
          <stop stopColor="#FF815B" />
          <stop offset="0.24804" stopColor="#FF815B" stopOpacity="0.869521" />
          <stop offset="0.822875" stopColor="#FF815B" stopOpacity="0.150116" />
          <stop offset="1" stopColor="#FF815B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.002 7.62252) rotate(99.2111) scale(1.22905 4.89718)"
        >
          <stop stopColor="#FF2F3C" />
          <stop offset="0.739036" stopColor="#FF2F3C" stopOpacity="0.119038" />
          <stop offset="1" stopColor="#FF2F3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.03 9.12326) rotate(105.173) scale(6.56109 1.72824)"
        >
          <stop stopColor="#FF2F3C" stopOpacity="0.72" />
          <stop offset="0.739036" stopColor="#FF2F3C" stopOpacity="0.119038" />
          <stop offset="1" stopColor="#FF2F3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.2955 14.8943) rotate(31.1593) scale(3.12217 14.1271)"
        >
          <stop stopColor="#CA0B4A" />
          <stop offset="0.680964" stopColor="#CA0B4A" stopOpacity="0.54" />
          <stop offset="1" stopColor="#CA0B4A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.851 7.24911) rotate(23.7601) scale(2.53816 9.24826)"
        >
          <stop stopColor="#F38758" />
          <stop offset="0.363071" stopColor="#F38758" stopOpacity="0.703001" />
          <stop offset="0.71349" stopColor="#F38758" stopOpacity="0.210142" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8086 12.164) rotate(28.8359) scale(1.87991 6.84982)"
        >
          <stop stopColor="#F38758" stopOpacity="0.86" />
          <stop offset="0.262244" stopColor="#F38758" stopOpacity="0.703001" />
          <stop offset="0.675284" stopColor="#F38758" stopOpacity="0.210142" />
          <stop offset="0.861713" stopColor="#F38758" stopOpacity="0.0623553" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.8778 17.4969) rotate(24.6769) scale(2.76941 10.0909)"
        >
          <stop stopColor="#F38758" />
          <stop offset="0.363071" stopColor="#F38758" stopOpacity="0.703001" />
          <stop offset="0.71349" stopColor="#F38758" stopOpacity="0.210142" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.97 24.8412) rotate(-160.017) scale(6.54206 13.3323)"
        >
          <stop stopColor="#CE5327" />
          <stop offset="0.844833" stopColor="#CE5327" stopOpacity="0.434204" />
          <stop offset="0.945717" stopColor="#CE5327" stopOpacity="0.12821" />
          <stop offset="1" stopColor="#CE5327" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.4036 6.58467) rotate(-176.122) scale(1.3128 5.24814)"
        >
          <stop stopColor="#CE5327" stopOpacity="0.65" />
          <stop offset="0.481357" stopColor="#CE5327" stopOpacity="0.434204" />
          <stop offset="0.738276" stopColor="#CE5327" stopOpacity="0.12821" />
          <stop offset="1" stopColor="#CE5327" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.8802 8.97024) rotate(-164.148) scale(1.3425 5.36686)"
        >
          <stop stopColor="#CE5327" stopOpacity="0.65" />
          <stop offset="0.481357" stopColor="#CE5327" stopOpacity="0.434204" />
          <stop offset="0.738276" stopColor="#CE5327" stopOpacity="0.12821" />
          <stop offset="1" stopColor="#CE5327" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.75321 15.4302) rotate(-154.011) scale(0.827935 6.97938)"
        >
          <stop stopColor="#CE5327" stopOpacity="0.87" />
          <stop offset="0.493785" stopColor="#CE5327" stopOpacity="0.53" />
          <stop offset="0.852631" stopColor="#CE5327" stopOpacity="0.123929" />
          <stop offset="1" stopColor="#CE5327" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.176 11.5229) rotate(-145.909) scale(0.351445 2.17636)"
        >
          <stop stopColor="#FF5E47" />
          <stop offset="1" stopColor="#FF5E47" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.78818 9.98635) rotate(29.1817) scale(1.51767 9.39831)"
        >
          <stop offset="0.25679" stopColor="#FF5E47" />
          <stop offset="0.779632" stopColor="#FF5E47" stopOpacity="0.216117" />
          <stop offset="1" stopColor="#FF5E47" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.7885 10.3072) rotate(-155.855) scale(0.487341 2.39291)"
        >
          <stop stopColor="#FF2F3C" />
          <stop offset="0.361378" stopColor="#FF2F3C" stopOpacity="0.44772" />
          <stop offset="0.739036" stopColor="#FF2F3C" stopOpacity="0.119038" />
          <stop offset="1" stopColor="#FF2F3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.4817 6.6213) rotate(-177.99) scale(2.02063 10.6728)"
        >
          <stop stopColor="#CA0B4A" />
          <stop offset="0.680964" stopColor="#CA0B4A" stopOpacity="0.183465" />
          <stop offset="1" stopColor="#CA0B4A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.5239 3.73665) rotate(-180) scale(1.07174 5.66084)"
        >
          <stop stopColor="#CA0B4A" />
          <stop offset="0.680964" stopColor="#CA0B4A" stopOpacity="0.183465" />
          <stop offset="1" stopColor="#CA0B4A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.80245 20.2818) rotate(22.249) scale(0.761191 4.78406)"
        >
          <stop stopColor="#F38758" />
          <stop offset="0.277249" stopColor="#F38758" stopOpacity="0.771452" />
          <stop offset="0.585568" stopColor="#F38758" stopOpacity="0.288453" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.4094 17.3337) rotate(29.7449) scale(0.683697 4.29701)"
        >
          <stop stopColor="#F38758" />
          <stop offset="0.277249" stopColor="#F38758" stopOpacity="0.771452" />
          <stop offset="0.585568" stopColor="#F38758" stopOpacity="0.288453" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.148 14.6603) rotate(37.4054) scale(0.626086 3.93492)"
        >
          <stop stopColor="#F38758" />
          <stop offset="0.277249" stopColor="#F38758" stopOpacity="0.771452" />
          <stop offset="0.585568" stopColor="#F38758" stopOpacity="0.288453" />
          <stop offset="1" stopColor="#F38758" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.2692 19.4101) rotate(-151.033) scale(4.79204 10.427)"
        >
          <stop stopColor="#CE5327" />
          <stop offset="0.638398" stopColor="#CE5327" stopOpacity="0.417024" />
          <stop offset="0.913732" stopColor="#CE5327" stopOpacity="0.123929" />
          <stop offset="1" stopColor="#CE5327" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.8588 27.423) rotate(-168.558) scale(11.2827 14.0502)"
        >
          <stop stopColor="#FFDA2F" />
          <stop offset="0.352487" stopColor="#FFC634" />
          <stop offset="1" stopColor="#FF8E41" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.1989 25.3461) rotate(-174.878) scale(11.5654 28.3172)"
        >
          <stop offset="0.627719" stopColor="#D7812D" stopOpacity="0" />
          <stop offset="0.728983" stopColor="#D7812D" stopOpacity="0.137125" />
          <stop offset="0.855913" stopColor="#D7812D" stopOpacity="0.404199" />
          <stop offset="1" stopColor="#D7812D" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.1535 32.0852) rotate(-135.822) scale(22.5823 26.6289)"
        >
          <stop stopColor="#D7812D" stopOpacity="0" />
          <stop offset="0.688904" stopColor="#D7812D" stopOpacity="0" />
          <stop offset="0.767023" stopColor="#D7812D" stopOpacity="0.103983" />
          <stop offset="0.837368" stopColor="#D7812D" stopOpacity="0.404199" />
          <stop offset="0.951078" stopColor="#D7812D" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.9551 9.66648) rotate(69.0442) scale(15.9103 13.8239)"
        >
          <stop offset="0.152609" stopColor="#FD5639" />
          <stop offset="0.835743" stopColor="#FE5534" stopOpacity="0.0858814" />
          <stop offset="1" stopColor="#FE5533" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint34_linear_196_4584"
          x1="18.569"
          y1="11.9385"
          x2="18.569"
          y2="16.0417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F95131" />
          <stop offset="1" stopColor="#F95131" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint35_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.8625 21.1524) rotate(143.063) scale(18.0614 13.0696)"
        >
          <stop offset="0.725303" stopColor="#F18A52" stopOpacity="0" />
          <stop offset="0.816284" stopColor="#F18A52" stopOpacity="0.230883" />
          <stop offset="1" stopColor="#F18A52" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.1481 25.7318) rotate(-148.858) scale(15.5031 25.5337)"
        >
          <stop stopColor="#F39D5D" stopOpacity="0" />
          <stop offset="0.928937" stopColor="#F39D5D" stopOpacity="0" />
          <stop offset="0.938719" stopColor="#F39D5D" stopOpacity="0.0581965" />
          <stop offset="0.950197" stopColor="#F39D5D" stopOpacity="0.180239" />
          <stop offset="0.983595" stopColor="#F39D5D" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_196_4584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.0967 26.5574) rotate(-178.108) scale(19.6482 19.9138)"
        >
          <stop stopColor="#F39D5D" stopOpacity="0" />
          <stop offset="0.939537" stopColor="#F39D5D" stopOpacity="0" />
          <stop offset="0.951154" stopColor="#F39D5D" stopOpacity="0.0581965" />
          <stop offset="0.96296" stopColor="#F39D5D" stopOpacity="0.180239" />
          <stop offset="0.980969" stopColor="#F39D5D" />
        </radialGradient>
      </defs>
    </svg>
  ),
};
