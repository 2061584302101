import { FC, useEffect, useRef, useState } from 'react';

import InputMask from 'react-input-mask';

import { useFormik } from 'formik';

import {
  Label,
  Listbox,
  Transition,
  ListboxOption,
  ListboxButton,
  ListboxOptions,
} from '@headlessui/react';

import { ResultContainer } from '..';

import validationSchema from './schema';

import { CREDIT_TYPES } from '@/constants';

import { selectIcon } from './consts';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const months = [
  { id: 12, name: '12 ay' },
  { id: 24, name: '24 ay' },
  { id: 36, name: '36 ay' },
  { id: 48, name: '48 ay' },
];

const bonusTypes = [{ id: 1, name: 'Keşbek' }];

function getInitialValues(key: string, value: { id: number; name: string }) {
  return {
    amount: '',
    [key]: value,
    mobileNumber: '+994 ',
  };
}

const CreditSearchForm: FC<any> = ({ currentTab, setCurrentTab }) => {
  const ref: any = useRef();

  const [values, setValues] = useState<any>(null);

  const isCash = currentTab === 'cash';
  const key = isCash ? 'monthAmount' : 'labelId';
  const value = isCash ? months[3] : bonusTypes[0];
  const selects = isCash ? months : bonusTypes;

  useEffect(() => {
    formik.setFieldValue(key, value);
    formik.resetForm({ values: getInitialValues(key, value) });
  }, [currentTab]);

  const formik: any = useFormik({
    onSubmit: values => setValues(values),
    initialValues: getInitialValues(key, value),
    validationSchema: () => validationSchema(key),
  });

  const errorMessage: any = (name: string) => {
    return formik.touched[name] && formik.errors[name] ? (
      <div className="text-red-600 text-[12px] mt-1 ml-1 absolute">{formik.errors[name]}</div>
    ) : null;
  };

  const handleMobileNumberChange = (value: string) => {
    const val = value?.startsWith('+994') ? (value ?? '') : '+994 ';
    formik.setFieldValue('mobileNumber', val);
  };

  useEffect(() => handleMobileNumberChange(ref?.current?.value), [ref]);

  return (
    <section id="creditSearchForm">
      <form
        onSubmit={formik.handleSubmit}
        className="max-w-[1048px] mx-auto flex flex-col gap-5 mb-8 lg:mt-12 xxs:mt-6"
      >
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
          {CREDIT_TYPES.map(item => (
            <div
              key={item.id}
              onClick={() => setCurrentTab(item.id)}
              className={`p-[1px] rounded-[20px] cursor-pointer ${item.id === currentTab ? 'bg-gradient-to-b from-[#5956E9] to-[#DB14F1]' : 'bg-white'}`}
            >
              <div className="p-8 flex flex-col gap-4 rounded-[20px] bg-white">
                <div className="flex items-center gap-2.5">
                  <item.icon />
                  <span className="leading-8 text-[#2F2F2F] font-semibold xxs:text-[18px] lg:text-2xl">
                    {item.name}
                  </span>
                </div>
                <p className="text-[#6E757C] xxs:text-[14px] lg:text-xl">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="p-8 rounded-[20px] bg-white flex flex-col md:flex-row gap-5">
          <div className="w-full md:w-[273px] relative">
            <label htmlFor="amount" className="block text-sm font-medium leading-6 text-[#091012]">
              İstədiyiniz məbləğ
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="amount"
                name="amount"
                placeholder="10 000"
                onBlur={formik.handleBlur}
                style={{ outline: 'none' }}
                value={formik.values.amount}
                onChange={formik.handleChange}
                className="block w-full rounded-lg border-0 h-[50px] py-4 px-5 text-[#091012] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errorMessage('amount')}
          </div>
          <Listbox
            value={formik.values[key]}
            onChange={o => {
              formik.setFieldValue(key, o);
            }}
          >
              <div className="w-full md:w-[273px]">
                <Label className="block text-sm font-medium leading-6 text-[#091012]">
                  {!isCash ? 'Bonus tipi' : 'Müddət'}
                </Label>
                <div className="relative mt-2">
                  <ListboxButton className="h-[50px] relative w-full cursor-default rounded-lg bg-white py-4 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm">
                    <span className="block truncate">{formik.values[key]?.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <selectIcon.icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>
                  <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-40 w-full data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {selects.map(value => (
                      <ListboxOption
                        key={value.id}
                        className={
                          classNames(
                            value.id === formik.values[key]?.id ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            `relative cursor-pointer ${value.id !== formik.values[key]?.id ? 'hover:bg-indigo-100' : ''} select-none py-2 pl-3 pr-9`,
                          )
                        }
                        value={value}
                      >
                        <span className="block truncate font-normal group-data-[selected]:font-semibold">
                          {value.name}
                        </span>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
                {errorMessage(key)}
              </div>
          </Listbox>

          <div className="w-full md:w-[273px]">
            <label
              htmlFor="mobileNumber"
              className="block text-sm font-medium leading-6 text-[#091012]"
            >
              Mobil nömrə
            </label>
            <div className="mt-2">
              <InputMask
                ref={ref}
                id="mobileNumber"
                name="mobileNumber"
                defaultValue="+994 "
                onBlur={formik.handleBlur}
                mask="+994 (99) 999 99 99"
                placeholder="77 777 77 77"
                style={{ outline: 'none' }}
                value={formik.values.mobileNumber}
                onChange={(e: any) => handleMobileNumberChange(e.target.value)}
                className="block w-full rounded-lg border-0 py-4 px-5 h-[50px] text-[#091012] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errorMessage('mobileNumber')}
          </div>
          <button
            type="submit"
            id="submitBtn"
            className="bg-[#2F2F2F] w-full md:w-[106px] h-[50px] self-end text-white font-semibold rounded-lg"
          >
            Axtar
          </button>
        </div>
      </form>

      <ResultContainer {...{ currentTab, values }} />
    </section>
  );
};

export default CreditSearchForm;
