import { useEffect, useMemo, useState } from 'react';

import ReactInputMask from 'react-input-mask';

import * as Yup from 'yup';

import { useFormik } from 'formik';

import { CREDIT_TYPES } from '@/constants';

import useTopOffersQuery from '@/queries/useTopOffersQuery';

import { fireIcon } from '../../shared/icons';

import { LABELS } from '../../shared/consts';
import { Loading } from '../ResultContainer/ui/Loading';

import { useSaveRequest } from '../ResultContainer/hooks/useSaveRequest';

import type { BankOfferTag } from '../ResultContainer/types';

export const toCapitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const validationSchema = (key: string) => {
  return Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\+994 \(\d{2}\) \d{3} \d{2} \d{2}$/, 'Invalid phone number format')
      .required('Mobile number is required'),
  });
};

function validatePhoneNumber(phoneNumber: string) {
  const regexPattern = /^\+994 \(\d{2}\) \d{3} \d{2} \d{2}$/;
  return regexPattern.test(phoneNumber);
}

const TopOffers = () => {
  const { data, isLoading, isSuccess, isError } = useTopOffersQuery() as any;

  const [creditType, setCreditType] = useState<string>('cash');
  // const [companyId, setCompanyId] = useState<string | null>(null);

  const isCash = creditType === 'cash';

  // const handleMobileNumberChange: any = (e: any) => {
  //   const { value } = e.target;
  //   if (value.startsWith('+994')) {
  //     formik.setFieldValue('mobileNumber', value);
  //   } else {
  //     formik.setFieldValue('mobileNumber', '+994 ');
  //   }
  // };

  const {
    reset,
    saveRequest,
    isPending: isSaveRequestPending,
    isSuccess: isSaveRequestSucceed,
  } = useSaveRequest(creditType);

  // const formik: any = useFormik({
  //   initialValues: {
  //     mobileNumber: '+994 ',
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: async values => {
  //     const payload: any = {
  //       companyId,
  //       phone: values?.mobileNumber,
  //     };

  //     const val = !isCash ? { labelId: 1 } : {};
  //     const requestPayload = { ...payload, ...val };

  //     void saveRequest(requestPayload);
  //   },
  // });

  const handleTabChange = (id: string) => {
    setCreditType(id);
  };

  // useEffect(() => {
  //   if (isSaveRequestSucceed)
  //     setTimeout(() => (formik.resetForm(), reset(), setCompanyId(null)), 1500);
  // }, [isSaveRequestSucceed]);

  const buttonText = useMemo(
    () => (isSaveRequestPending ? <Loading /> : isSaveRequestSucceed ? 'Göndərildi' : 'Göndər'),
    [isSaveRequestPending, isSaveRequestSucceed],
  );

  return (
    <section className="lg:pt-16 xxs:pt-8">
      <div className="mx-auto max-w-[1048px]">
        <div className="flex gap-2 items-center mb-8">
          <fireIcon.icon />
          <h2 className="text-2xl font-bold leading-9 text-[#2f2f2f]">Ayın ən yaxşı təklifləri!</h2>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex gap-3 items-center">
            {CREDIT_TYPES.map(item => (
              <button
                className={`${
                  creditType === item?.id ? 'text-white bg-[#5956E9]' : 'text-[#25282B] bg-white'
                } font-semibold rounded-full min-w-[123px] py-2 px-4`}
                key={item?.id}
                type="button"
                onClick={() => handleTabChange(item?.id)}
              >
                {item?.name}
              </button>
            ))}
          </div>

          {isLoading ? (
            <div className="mx-auto mt-4">
              <Loading color={'bg-[#5956E9]'} />
            </div>
          ) : (
            <div className="grid lg:grid-cols-[repeat(auto-fit,_minmax(336px,_350px))] gap-5 xxs:lg:grid-cols-[repeat(auto-fit,_minmax(336px,_1fr))]">
              {isSuccess &&
                data?.object
                  ?.filter((item: any) => item?.type === toCapitalize(creditType))
                  .map((item: any) => {
                    return (
                      <div key={item?.id} className="bg-white border p-6 rounded-2xl">
                        <div className="flex flex-col gap-6 justify-center">
                          <div className="flex gap-2 items-center">
                            <div className="flex items-center justify-center rounded-lg w-12 h-12 bg-[#F6F6F6]">
                              <img
                                alt={item?.bankName}
                                src={`data:image/${item?.bankImageExtension};base64, ${item?.bankImage}`}
                              />
                            </div>
                            <span className="text-[#25282B] font-semibold">{item?.bankName}</span>
                          </div>
                          {isCash ? (
                            <div className="flex flex-col gap-6">
                              <div className="flex flex-col gap-1">
                                <span className="text-[#6E757C] font-medium">
                                  {LABELS.day.label}
                                </span>
                                <div className="text-[#25282B] font-semibold flex items-center gap-1">
                                  <span>{`${item?.amount} ${LABELS.creditAmount.suffix}`}</span>
                                  <fireIcon.icon width={24} height={24} />
                                </div>
                              </div>
                              <div className="flex flex-col gap-1">
                                <span className="text-[#6E757C] font-medium">
                                  {LABELS.percent.label}
                                </span>
                                <span className="text-[#25282B] font-semibold">
                                  {`${item?.interest} ${LABELS.percent.suffix}`}
                                </span>
                              </div>
                              <div className="flex flex-col gap-1">
                                <span className="text-[#6E757C] font-medium">
                                  {LABELS.date.label}
                                </span>
                                <span className="text-[#25282B] font-semibold">
                                  {`${item?.monthCount} ${LABELS.date.suffix}`}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-6">
                              <div className="flex flex-col gap-1">
                                <span className="text-[#6E757C] font-medium">
                                  {LABELS.day.label}
                                </span>
                                <div className="text-[#25282B] font-semibold flex items-center gap-1">
                                  <span>{`${item?.gracePeriod ? item?.gracePeriod : 'Məlum deyil'}`}</span>
                                </div>
                              </div>
                              <div className="flex flex-col gap-1">
                                <span className="text-[#6E757C] font-medium">
                                  {LABELS.percent.label}
                                </span>
                                <div className="text-[#25282B] font-semibold flex items-center gap-1">
                                  <span>{`${item?.amount} ${LABELS.creditAmount.suffix}`}</span>
                                  <fireIcon.icon width={24} height={24} />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex items-center gap-2 flex-wrap">
                            {item?.labels.map((o: BankOfferTag) => (
                              <div
                                className={`${
                                  item?.labels[0].id === o?.id
                                    ? 'text-white bg-[#e541ed]'
                                    : 'text-[#25282B] bg-[#F6F6F6]'
                                } font-semibold rounded-full min-w-[84px] py-1 px-2 text-center text-[12px]`}
                                key={item?.id}
                              >
                                {o?.name}
                              </div>
                            ))}
                          </div>
                          <a
                            target='_blank'
                            href={item?.productLink}
                            className="text-center py-3 rounded-md w-full text-white font-semibold border-none outline-none bg-[#5956E9]"
                          >
                            Sorğu göndər
                          </a>
                          {/* {item.id === companyId && (
                            <div className="flex items-center gap-2">
                              <div className="w-full">
                                <ReactInputMask
                                  id="mobileNumber"
                                  name="mobileNumber"
                                  defaultValue="+994 "
                                  onBlur={formik.handleBlur}
                                  mask="+994 (99) 999 99 99"
                                  placeholder="77 777 77 77"
                                  style={{ outline: 'none' }}
                                  disabled={isSaveRequestPending || isSaveRequestSucceed}
                                  value={formik.values.mobileNumber}
                                  onChange={handleMobileNumberChange}
                                  className="block w-full rounded-lg border-0 py-2 px-2 text-[#091012] shadow-sm ring-1 ring-inset ring-gray-300 h-[48px] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-[2rem]"
                                />
                              </div>
                              <button
                                type="submit"
                                disabled={!validatePhoneNumber(formik.values.mobileNumber)}
                                className={`text-center flex justify-center items-center h-[48px] lg:w-[150px] xxs:w-[140px]
                              text-[14px] rounded-md text-white font-semibold border-none outline-none ${validatePhoneNumber(formik.values.mobileNumber) ? 'bg-[#5956E9] cursor-pointer' : 'bg-[#706ea1]'} `}
                              >
                                {buttonText}
                              </button>
                            </div>
                          )} */}
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
          {isError && <p>Something went wrong!</p>}
        </div>
      </div>
    </section>
  );
};

export default TopOffers;
