import { useQuery } from '@tanstack/react-query';

import { smartListCard } from '@/repositories';

interface ICardParams {
  amount: number;
  labelId: number;
}

function fetch(params: ICardParams) {
  return smartListCard(params);
}

export default function useCardListQuery(params: ICardParams, options?: any) {
  return useQuery({
    queryKey: ['smartList', params],
    queryFn: () => fetch(params),
    ...options,
  });
}
