import { useSaveCardRequest, useSaveCashRequest } from '@/mutations/useSaveRequestMutation';

export const useSaveRequest = (currentTab: string) => {
  const isCash = currentTab === 'cash';
  const {
    mutate: saveRequest,
    isPending,
    isSuccess,
    reset,
  } = isCash ? useSaveCashRequest() : useSaveCardRequest();

  return { saveRequest, isPending, isSuccess, reset };
};
