export const problemAds = [
  {
    title: 'Vaxta qənaət',
    description: 'Bank məhsulların bir yerdə müqayisə edin.',
    icon: () => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="20" stroke="#141B34" strokeWidth="2" />
        <path
          opacity="0.5"
          d="M19 19L25.9998 25.9993M32 16L22 26"
          stroke="#E541ED"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: 'Şəffaflıq',
    description: 'Ən son təklifləri şəffaflıqla müqayisə edin.',
    icon: () => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M4.75 41V38.4333C4.75 36.3633 4.2756 34.3218 3.36436 32.4704L2.78154 31.2863C1.60995 28.9059 1 26.2811 1 23.6198V13.381C1 11.803 2.2592 10.5238 3.8125 10.5238C5.3658 10.5238 6.625 11.803 6.625 13.381V5.7619C6.625 4.18395 7.8842 2.90476 9.4375 2.90476C10.9908 2.90476 12.25 4.18395 12.25 5.7619V12.4286L17.1068 2.56084C17.5776 1.60425 18.54 1 19.5928 1C21.5692 1 22.9142 3.03656 22.1646 4.89438L17.875 14.9017C17.875 14.9017 18.8125 14.3373 20.8068 14.3373C25.5319 14.3373 29.5332 17.4068 30.8891 21.6425C31.432 23.3388 29.9003 24.8115 28.0728 24.8115C26.6468 24.8115 25.3996 23.875 24.4546 22.8366C24.038 22.3788 23.5168 21.938 22.8848 21.6062C20.3474 19.989 16.9614 21.9352 16.9614 24.8115C16.9614 27.6878 20.3474 29.634 22.8848 28.0168C23.0405 27.9173 23.2341 27.7702 23.4379 27.5821C24.7391 26.3806 26.264 24.8115 28.0586 24.8115C29.8532 24.8115 31.3561 26.2585 30.8136 27.9216C29.9886 30.4508 28.1996 32.5515 25.9689 33.8533C24.0165 34.9926 21.6292 36.155 21.625 38.4423C21.6235 39.2729 21.625 40.1347 21.625 41H4.75Z"
          fill="#E541ED"
        />
        <path
          d="M4.75 41V38.4333C4.75 36.3634 4.2756 34.3218 3.36436 32.4704L2.78154 31.2863C1.60995 28.9059 1 26.2811 1 23.6198V13.381C1 11.803 2.2592 10.5238 3.8125 10.5238C5.3658 10.5238 6.625 11.803 6.625 13.381M21.625 41C21.625 40.1347 21.6235 39.2729 21.625 38.4423C21.6292 36.155 24.0165 34.9926 25.9689 33.8533C28.1996 32.5515 29.9886 30.4508 30.8136 27.9216C31.3561 26.2585 29.8532 24.8115 28.0586 24.8115C26.264 24.8115 24.7391 26.3806 23.4379 27.5821C23.2341 27.7702 23.0405 27.9173 22.8848 28.0168C20.3474 29.634 16.9614 27.6878 16.9614 24.8115C16.9614 21.9352 20.3474 19.989 22.8848 21.6062C23.5168 21.938 24.038 22.3788 24.4546 22.8366C25.3996 23.875 26.6468 24.8115 28.0729 24.8115C29.9003 24.8115 31.4321 23.3388 30.8891 21.6425C29.5332 17.4068 25.5319 14.3373 20.8068 14.3373C18.8125 14.3373 17.875 14.9017 17.875 14.9017L22.1646 4.89438C22.9142 3.03656 21.5692 1 19.5928 1C18.54 1 17.5776 1.60425 17.1068 2.56084L12.25 12.4286M12.25 12.4286L11.3548 14.2474M12.25 12.4286V5.7619C12.25 4.18395 10.9908 2.90476 9.4375 2.90476C7.8842 2.90476 6.625 4.18395 6.625 5.7619V13.381M6.625 13.381V20.0476"
          stroke="#091012"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: 'Fərdi tövsiyələr',
    description: 'Ehtiyaclarınıza uyğunlaşdırılmış ən yaxşı təklifi seçin.',
    icon: () => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 4.02457C24.6687 4.00825 24.3353 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 22.2735 43.7812 20.5981 43.3699 19"
          stroke="#141B34"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M16 30C17.8242 32.4289 20.7287 34 24 34C27.2713 34 30.1758 32.4289 32 30"
          stroke="#E541ED"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M20 19H17.4142C16.5087 19 15.6403 19.3597 15 20M28 19H30.5858C31.4913 19 32.3597 19.3597 33 20"
          stroke="#E541ED"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.7762 4.66235C33.6534 3.56573 35.2917 4.00765 36.276 4.71159C36.6795 5.00022 36.8813 5.14453 37 5.14453C37.1187 5.14453 37.3205 5.00022 37.724 4.71159C38.7083 4.00765 40.3466 3.56573 42.2238 4.66235C44.6874 6.10153 45.2448 10.8495 39.5623 14.8551C38.48 15.6181 37.9388 15.9996 37 15.9996C36.0612 15.9996 35.52 15.6181 34.4377 14.8551C28.7552 10.8495 29.3126 6.10153 31.7762 4.66235Z"
          stroke="#141B34"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
  },
];
