import Link from 'next/link';

import { FC, useCallback } from 'react';

import { LABELS } from '../../../shared/consts';

import { fireIcon } from '../../../shared/icons';

import type { BankOffer } from '../types';

export const CashBankInfo: FC<{
  item: BankOffer;
  isSuccess: boolean;
  isLoading: boolean;
  offeredBankId: number | null;
  onSendOffer: (id: number) => void;
}> = ({ item, onSendOffer, isLoading, isSuccess, offeredBankId }) => {
  const buttonText = useCallback(
    (id: number | null) =>
      // offeredBankId === id && isLoading ? (
      //   <Loading />
      // ) : offeredBankId === id && isSuccess ? (
      //   <Success />
      // ) : (
        'Sorğu göndər',
      // ),
    [offeredBankId, isLoading, isSuccess],
  );

  return (
    <>
      <div>
        <div className="flex gap-2 items-center min-w-[283px]">
          <div className="flex items-center justify-center rounded-lg w-12 h-12 bg-[#F6F6F6]">
            <img src={`data:image/${item?.bankImageExtension};base64, ${item?.bankImage}`} />
          </div>
          <span className="text-[#25282B] font-semibold">{item?.bankName}</span>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 gap-6 xxs:grid-cols-1 lg:place-items-center">
        <div className="flex flex-col gap-1">
          <span className="text-[#6E757C] font-medium">{LABELS.creditAmount.label}</span>
          <div className="text-[#25282B] font-semibold flex lg:items-center xxs:items-end gap-1">
            <span>{`${item?.amount ? item?.amount + LABELS.creditAmount.suffix : 'Məlum deyil'}`}</span>
            <fireIcon.icon width={24} height={24} />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-[#6E757C] font-medium">{LABELS.percent.label}</span>
          <span className="text-[#25282B] font-semibold">
            {`${item?.interest} ${LABELS.percent.suffix}`}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-[#6E757C] font-medium">{LABELS.date.label}</span>
          <span className="text-[#25282B] font-semibold">
            {`${item?.monthCount} ${LABELS.date.suffix}`}
          </span>
        </div>
        <Link
          target='_blank'
          href={item.productLink || ''}
          // onClick={() => onSendOffer(item?.id)}
          className={`flex justify-center items-center text-center lg:min-h-[48px] xxs: min-h-[32px] lg:py-3 xxs:py-2 lg:text-base xxs:text-[12px] rounded-md w-full text-white font-semibold border-none outline-none ${isSuccess ? 'bg-[#2AC769]' : 'bg-[#5956E9]'} max-w-[152px]`}
        >
          {buttonText(item?.id)}
        </Link>
      </div>
    </>
  );
};
