import useCardListQuery from '@/queries/useCardListQuery';
import useCashListQuery from '@/queries/useCashListQuery';

export const useFetchList = (values: any, currentTab: any): any => {
  const cardQuery: any = useCardListQuery(values, { enabled: false });
  const cashQuery: any = useCashListQuery(
    { ...values, monthCount: values?.monthAmount?.id },
    { enabled: false },
  );

  const isCash = currentTab === 'cash';

  return {
    data: isCash ? cashQuery.data : cardQuery.data,
    refetch: isCash ? cashQuery.refetch : cardQuery.refetch,
    isLoading: isCash ? cashQuery.isLoading : cardQuery.isLoading,
    isSuccess: isCash ? cashQuery.isSuccess : cardQuery.isSuccess,
    isRefetching: isCash ? cashQuery.isFetching : cardQuery.isFetching,
  };
};
