import { saveCardRequest, saveCashRequest } from '@/repositories';

import { useMutation } from '@tanstack/react-query';

export const useSaveCardRequest = () => {
  return useMutation({ mutationFn: saveCardRequest });
};

export const useSaveCashRequest = () => {
  return useMutation({ mutationFn: saveCashRequest });
};
