import type { BankOffer, Labels } from './types';

export const icons = {
  checkIcon: () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M7 11.75C7 11.75 8.6 12.6625 9.4 14C9.4 14 11.8 8.75 15 7"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  sad: () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="20" fill="#E541ED" fillOpacity="0.2" />
      <circle
        cx="24"
        cy="24"
        r="20"
        stroke="#141B34"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 34C17.8242 31.5711 20.7287 30 24 30C27.2713 30 30.1758 31.5711 32 34"
        stroke="#141B34"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0179 18H16Z"
        fill="#E541ED"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 18H31.9821Z"
        fill="#E541ED"
        fillOpacity="0.2"
      />
      <path
        d="M16.0179 18H16M32 18H31.9821"
        stroke="#141B34"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const TOP_BANK_OFFERS: any = [
  {
    bankName: 'Kapital Bank',
    bankIcon: 'Kapitalbank',
    bankId: 'kapitalbank',
    creditAmount: '20 000',
    percent: '9.5',
    date: '48',
    tags: [
      {
        text: 'Ən sərfəli',
        id: 'enSerfeli',
      },
      {
        text: 'Komissiya - 0%',
        id: 'zeroComission',
      },
      {
        text: 'Karta mədaxil',
        id: 'kartaMedaxil',
      },
    ],
  },
  {
    bankName: 'Azərbaycan Beynəlxalq Bankı',
    bankIcon: 'ABB',
    bankId: 'abb',
    creditAmount: '20 000',
    percent: '10',
    date: '60',
    tags: [
      {
        text: 'Ən sərfəli',
        id: 'enSerfeli',
      },
      {
        text: 'Komissiya - 0%',
        id: 'zeroComission',
      },
      {
        text: 'Karta mədaxil',
        id: 'kartaMedaxil',
      },
    ],
  },
  {
    bankName: 'Unibank',
    bankIcon: 'Unibank',
    bankId: 'unibank',
    creditAmount: '15 000',
    percent: '9.5',
    date: '48',
    tags: [
      {
        text: 'Ən sərfəli',
        id: 'enSerfeli',
      },
      {
        text: 'Komissiya - 0%',
        id: 'zeroComission',
      },
      {
        text: 'Karta mədaxil',
        id: 'kartaMedaxil',
      },
    ],
  },
];
