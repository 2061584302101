import { useQuery } from '@tanstack/react-query';

import { topOffers } from '@/repositories';

function fetch() {
  return topOffers();
}

export default function useTopOffersQuery(params?: any, options?: any) {
  return useQuery({
    queryKey: ['topOffers'],
    queryFn: () => fetch(),
    ...options,
  });
}
