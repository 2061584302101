import { problemAds } from './consts';

const Platform = () => {
  return (
    <section className="lg:mt-0 xxs:p-0 xxs:mt-6">
      <div className="flex flex-col lg:gap-6 xxs:gap-4">
        <h2 className="font-bold leading-9 text-[#2f2f2f] text-2xl">
          Niyə biz ?
        </h2>
        <div className="grid grid-cols-[repeat(auto-fit,_minmax(336px,_1fr))] gap-5">
          {problemAds.map(item => (
            <div
              key={item.title}
              className="flex flex-col p-6 rounded-2xl bg-white text-black text-base min-h-[200px]"
            >
              <item.icon />
              <span className="font-bold mt-4">{item.title}</span>
              <span className="font-normal mt-3">{item.description}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Platform;
